import { store } from '@redux/store'
import { setOrder } from '@redux/modules/checkout'
import { updatePayment } from '@services/checkout'
import { getOrder } from '../global'
import { checkoutStepAnalytics } from '../../google-tag-manager'

export const getRTGFinance = () => {
  const order = getOrder()
  let rtgFin
  if (order && order.paymentInfo) {
    const rtgFins = order.paymentInfo.filter(
      payment => payment.paymentType === 'FIN' || payment.paymentType === 'GEN' || payment.paymentType === 'DBUY',
    )
    if (rtgFins && rtgFins.length > 0) {
      const rtgTempFin = rtgFins[0]
      rtgFin = rtgTempFin
    }
  }
  return rtgFin
}

export const setOrderFinancePlanInfo = (info, field) => {
  let order = getOrder()
  if (field) {
    order = {
      ...order,
      financePlan: {
        ...order.financePlan,
        [field]: info,
      },
    }
  } else {
    order = {
      ...order,
      financePlan: {
        ...info,
      },
    }
  }
  store.dispatch(setOrder(order))
}

/**
 * Sets the Synchrony eQuick pre-approval info in order.financePlan based on the value of decision. If decision
 * is omitted or not one of ['APPROVED', 'CREDIT_APPROVED', or 'CREDIT_PENDING'], then the eQuick info is removed
 * from financePlan, otherwise the eQuick info is updated.
 *
 * @param {string} [decision]
 * @returns null
 */
export const setEquickDecision = decision => {
  const { financePlan } = getOrder()
  let newFinancePlan = { code: financePlan.code, hasPayments: financePlan.hasPayments }

  if (['APPROVED', 'CREDIT_APPROVED', 'CREDIT_PENDING'].includes(decision)) {
    newFinancePlan = {
      ...financePlan,
      isPrequalified: true,
      quickScreenInfo: {
        ...financePlan.quickScreenInfo,
        decision,
      },
    }
  }

  return setOrderFinancePlanInfo(newFinancePlan)
}

export const getFinanceCodeFromOrder = () => {
  const order = getOrder()
  let finCode
  if (order && order.paymentInfo) {
    const fin = order.paymentInfo.filter(
      payment => payment.paymentType === 'FIN' || payment.paymentType === 'GEN' || payment.paymentType === 'DBUY',
    )
    if (fin && fin.length > 0) {
      finCode = fin[0].paymentProperties.financePlan
    }
  }
  return finCode
}

export const validateRTGCreditInfo = async (event, plan, rtgCreditInfo, setRTGCreditState, closeModal) => {
  event.preventDefault()
  await setRTGCreditState(true, 'loading', async () => {
    const order = getOrder()
    const entries = Object.entries(rtgCreditInfo)
    const invalidFields = []
    for (let i = 0; i < entries.length; i++) {
      const entryKey = entries[i][0]
      const entryData = entries[i][1]
      if (entryData === '') {
        if (plan.financeCode === 'GENESIS' && entryKey === 'phoneNumber') {
          invalidFields.push(entryKey)
        } else if (entryKey !== 'phoneNumber') {
          invalidFields.push(entryKey)
        }
      } else if (entryKey === 'finance' && (entryData.length < 16 || Number.isNaN(entryData.toString()))) {
        invalidFields.push('finance')
      } else if (entryKey === 'acknowledge' && !entryData) {
        invalidFields.push('acknowledge')
      } else if (entryKey === 'terms' && !entryData) {
        invalidFields.push('terms')
      }
    }
    setRTGCreditState({ loading: invalidFields < 1, invalidFields })
    if (invalidFields.length < 1) {
      try {
        let rtgPaymentInfo = []
        const giftCards = order?.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
        if (giftCards.length > 0) {
          rtgPaymentInfo = giftCards
        }
        rtgPaymentInfo.push({
          paymentType: plan.financeCode === 'GENESIS' ? 'GEN' : 'FIN',
          paymentProperties: {
            accountNumber: rtgCreditInfo.cardNumber,
            financePlan: plan.financeCode,
            encryptedFinancePlans: plan.encryptedFinanceCodes,
            zip: rtgCreditInfo.zip,
            hasPayments: plan.downPaymentRequired,
            phoneNumber: rtgCreditInfo.phoneNumber,
          },
        })
        const data = await updatePayment({
          paymentInfo: rtgPaymentInfo,
          orderId: order ? order.orderId : null,
        }).catch(err => {
          const { message } = err.response.data.error
          if (err?.response?.data?.error?.message?.includes('exceeds')) {
            const messageArr = message.split(' ')
            setRTGCreditState([`exceeds ${messageArr[messageArr.length - 1]}`], 'invalidFields')
          } else if (err?.response?.data?.error?.message?.includes('phone number does not match Genesis')) {
            setRTGCreditState({
              invalidFields: ['phoneMatch'],
              loading: false,
            })
          } else if (message.includes('Failed to retrieve account')) {
            setRTGCreditState({
              invalidFields: ['financeLength'],
              loading: false,
            })
          } else {
            throw err
          }
          setRTGCreditState(false, 'loading')
        })
        const genesisPayment = rtgPaymentInfo.find(payment => payment.paymentType === 'GEN')
        const genesisRemainingBalance = { hasRemaining: false, remainingBalance: 0 }
        if (data?.amountDue > 0 && genesisPayment) {
          genesisRemainingBalance.hasRemaining = true
          genesisRemainingBalance.remainingBalance = data.amountDue

          setRTGCreditState({ genesisRemainingBalance })
        }
        setRTGCreditState({
          rtgCreditInfo: {
            acknowledge: false,
            terms: false,
          },
        })
        if (data?.orderId) {
          const fin = data.paymentInfo.find(
            ({ paymentType }) => paymentType === 'FIN' || paymentType === 'DBUY' || paymentType === 'GEN',
          )

          if (data?.amountDue <= 0) {
            store.dispatch(setOrder({ ...data, isNonFinanceCredit: true }))
            checkoutStepAnalytics('review')
            closeModal()
            return
          }

          if (fin?.authorizedAmount === 0) {
            throw new Error('No available credit balance remaining.')
          }

          store.dispatch(
            setOrder({
              ...data,
              selectedPaymentType: 'Credit',
              isNonFinanceCredit: true,
            }),
          )
          closeModal()
          setRTGCreditState({
            success: true,
            loading: false,
          })
        }
      } catch (err) {
        setRTGCreditState({
          invalidFields: ['finance'],
          loading: false,
        })
      }
    }
  })
}

export const removeFinancingPlan = async (setLoading = () => {}, onFinClose) => {
  const order = getOrder()
  if (order && order.paymentInfo) {
    let paymentInfo = []
    const payments = order.paymentInfo.filter(
      payment =>
        payment.paymentType !== 'FIN' &&
        payment.paymentType !== 'CYBERV3' &&
        payment.paymentType !== 'GEN' &&
        payment.paymentType !== 'DBUY',
    )
    if (payments.length > 0) {
      paymentInfo = payments
    }
    const data = await updatePayment({
      paymentInfo,
      orderId: order.orderId,
    }).catch(err => {
      setLoading(false)
      console.error('Error removing financing plan:', err)
    })
    store.dispatch(
      setOrder({
        ...data,
        selectedPaymentType: 'Credit',
      }),
    )
    setLoading(false)
    if (onFinClose) onFinClose()
  }
}
